import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const DashboardAdmin = () => import('@/views/menu/dashboard-admin/DashboardAdmin')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const ResetPassword = () => import('@/views/pages/ResetPassword')


// Account
const AccountSetting = () => import('@/views/menu/account/accountSetting/AccountSetting')

// Mining Earning
const MiningEarning = () => import('@/views/menu/order/MiningEarning/MiningEarning')

// User
const Users = () => import('@/views/users/Users')
const UsersDetail = () => import('@/views/users/UsersDetail')

// Withdraw
const Withdraw = () => import('@/views/withdraw/Withdraw')

Vue.use(Router)

function guardMyroute(to, from, next)
{
 var isAuthenticated= false;
//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 
if(localStorage.getItem('status') =='1')
  isAuthenticated = true;
 else
  isAuthenticated= false;
 if(isAuthenticated) 
 {
  next(); // allow to enter route
 } 
 else
 {
  next('/pages/login'); // go to '/login';
 }
}

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})


function configRoutes () {
  if(localStorage.getItem('role') =='1'){
    return [
      {
        path: '/',
        redirect: '/dashboard-admin',
        name: 'Home',
        beforeEnter : guardMyroute,
        component: TheContainer,
        children: [
          {
            path: 'dashboard-admin',
            name: 'Dashboard',
            component: DashboardAdmin
          },
          {
            path: 'users',
            meta: {
              label: 'Users'
            },
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                name: 'Users',
                component: Users
              },
              {
                path: ':id',
                name: 'Users Detail',
                component: UsersDetail 
              },
              {
                path: 'add',
                name: 'Add User',
                component: UsersDetail 
              }
            ]
          },
          {
            path: 'withdraw',
            meta: {
              label: 'Withdraw'
            },
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                name: 'Withdraw',
                component: Withdraw
              }
            ]
          },
          {
            path: 'account-setting',
            name: 'Account Setting',
            component: AccountSetting
          }
        ]
      },
      {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '404',
            name: 'Page404',
            component: Page404
          },
          {
            path: '500',
            name: 'Page500',
            component: Page500
          },
          {
            path: 'login',
            name: 'Login',
            component: Login
          },
          {
            path: 'register',
            name: 'Register',
            component: Register
          },
          {
            // path: 'cmVzZXQtcGFzc3dvcmQ=/:id',
            path: 'reset-password',
            name: 'Reset Password',
            component: ResetPassword
          },
        ]
      }
    ]
  }else{
    return [
      {
        path: '/',
        redirect: '/dashboard',
        name: 'Home',
        beforeEnter : guardMyroute,
        component: TheContainer,
        children: [
          {
            path: 'dashboard',
            name: 'Dashboard',
            component: Dashboard
          },
          {
            path: 'mining-earning',
            name: 'Mining Earning',
            component: MiningEarning
          },
          {
            path: 'account-setting',
            name: 'Account Setting',
            component: AccountSetting
          }
        ]
      },
      {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: '404',
            name: 'Page404',
            component: Page404
          },
          {
            path: '500',
            name: 'Page500',
            component: Page500
          },
          {
            path: 'login',
            name: 'Login',
            component: Login
          },
          {
            path: 'register',
            name: 'Register',
            component: Register
          },
          {
            // path: 'cmVzZXQtcGFzc3dvcmQ=/:id',
            path: 'reset-password',
            name: 'Reset Password',
            component: ResetPassword
          }
        ]
      }
    ]
  }

}

